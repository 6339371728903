/* eslint-disable @next/next/no-img-element */
import Head from 'next/head';
import {useRouter} from 'next/router';

import {Footer} from '../components/Footer';
import {NavItems, NavPopover} from '../components/Header';
import {IconInstagram, IconTwitter} from '../components/icons/SocialIcons';

export default function Home() {
  const router = useRouter();
  const path = router.asPath;

  return (
    <div className={'h-screen'}>
      <Head>
        <title>Train as Me</title>
        <meta name="description" content="" />
        <meta
          name="apple-itunes-app"
          content={`app-id=1591739659, app-argument=${
            typeof window !== 'undefined' ? window.location : 'https://www.trainasme.com' + path
          }`}></meta>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <header className="relative flex flex-col min-h-full">
        <div className="flex flex-col flex-grow px-4 sm:px-6 md:px-8">
          {/* Background */}
          <div className="absolute inset-0 bottom-0 bg-bottom bg-no-repeat bg-slate-900 dark:bg-[#0B1120] index_beams__3fKa4">
            <div
              className="absolute inset-0 bg-grid-slate-900/[0.04] bg-[bottom_1px_center] dark:bg-grid-slate-400/[0.05] dark:bg-bottom dark:border-b dark:border-slate-100/5"
              style={{
                maskImage:
                  'linear-gradient(rgba(0, 0, 0, 0.3) 75px, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))',
                backgroundImage: 'url(/bg-one-arm-pushups.jpg)',
              }}></div>
          </div>

          {/* Header nav */}
          <div className="relative pt-6 lg:pt-8 flex items-center justify-between text-slate-50 font-semibold text-sm leading-6 dark:text-slate-200">
            <div className="text-slate-50 dark:text-white text-xl w-auto h-5">Train as Me</div>

            <div className="flex items-center">
              <NavPopover className="-my-1 ml-2 -mr-1" display="md:hidden" />

              <div className="hidden md:flex items-center">
                <nav>
                  <ul className="flex items-center space-x-8">
                    <NavItems />
                  </ul>
                </nav>

                <div className="flex items-center border-l border-slate-200 ml-6 pl-6 dark:border-slate-800">
                  <a
                    href="https://twitter.com/trainasme"
                    className="flex items-center text-slate-400 hover:text-slate-50 dark:hover:text-slate-300">
                    <span className="sr-only">Train as Me on Twitter</span>
                    <div className="relative inline-block h-5 w-5 fill-slate-50 hover:fill-sky-500 dark:hover:fill-sky-400">
                      <IconTwitter />
                    </div>
                  </a>
                  <a
                    href="https://instagram.com/trainasme"
                    className="flex items-center pl-6 text-slate-400 hover:text-slate-50 dark:hover:text-slate-300">
                    <span className="sr-only">Train as Me on Instagram</span>
                    <div className="relative inline-block h-5 w-5 fill-slate-50 hover:fill-sky-500 dark:hover:fill-sky-400">
                      <IconInstagram />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="relative flex-grow flex flex-col justify-center max-w-5xl mx-auto">
            <h1 className="text-white font-extrabold text-4xl sm:text-5xl lg:text-6xl tracking-tight text-center dark:text-white">
              Train as Me
            </h1>
            <p className="mt-6 text-lg text-slate-50 text-center max-w-3xl mx-auto dark:text-slate-400">
              A new training platform for sharing your exercises and workouts.
            </p>

            <div className="mt-12 text-center max-w-3xl mx-auto flex items-center space-x-8">
              <a href="https://apps.apple.com/app/train-as-me/id1591739659">
                <img
                  className="text-white"
                  width="130"
                  height="46"
                  alt="Apple App Store"
                  src="/AppStoreBadge_light.svg"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.trainasme">
                <img
                  className="text-white"
                  width="143"
                  height="46"
                  alt="Google Play Store"
                  src="/PlayStoreBadge.png"
                />
              </a>
            </div>
            <p className="mt-3 text-md text-slate-50 text-center max-w-3xl mx-auto dark:text-slate-400">
              by invite only
            </p>
          </div>
        </div>
      </header>

      <Footer />
    </div>
  );
}
