import Link from 'next/link';
import * as React from 'react';

export function Footer() {
  return (
    <footer>
      <div className="py-3 page-content space-y-6">
        {/* <div className="text-center">
          <Link href="/">
            <img
              className="h-10 inline-block cursor-pointer mx-10"
              src="/images/trainasme-icon.svg"
              alt="Train as Me"
            />
          </Link>
        </div> */}

        <div className="text-center">
          <Link href="/">
            <a className="inline-block mb-4 mx-4 text-md">Home</a>
          </Link>

          <a href="mailto:contact@trainasme.com" className="inline-block mb-4 mx-4 text-md">
            Contact
          </a>

          <Link href="/legal/privacy-policy">
            <a className="inline-block mb-4 mx-4 text-md">Privacy Policy</a>
          </Link>

          <Link href="/legal/terms-and-conditions">
            <a className="inline-block mb-4 mx-4 text-md">Terms and Conditions</a>
          </Link>
        </div>
      </div>
    </footer>
  );
}
