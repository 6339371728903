/* eslint-disable @next/next/no-img-element */
import {Dialog} from '@headlessui/react';
import clsx from 'clsx';
import Link from 'next/link';
import Router from 'next/router';
import * as React from 'react';

export function Header() {
  return (
    <header>
      <div className="text-left pl-6 pr-6 pt-4 pb-4 border-b">
        <Link href="/">
          <a>
            <img
              className="inline-block cursor-pointer"
              src="/images/trainasme-icon.svg"
              alt="Train as Me"
            />
          </a>
        </Link>
      </div>
    </header>
  );
}

// Inspired by Tailwindcss
// https://github.com/tailwindlabs/tailwindcss.com/blob/master/src/components/Header.js

export function NavPopover({display = 'md:hidden', className, ...props}) {
  let [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (!isOpen) return;
    function handleRouteChange() {
      setIsOpen(false);
    }
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [isOpen]);

  return (
    <div className={clsx(className, display)} {...props}>
      <button
        type="button"
        className="text-slate-50 w-8 h-8 flex items-center justify-center hover:text-sky-500 dark:text-slate-50 dark:hover:text-sky-500"
        onClick={() => setIsOpen(true)}>
        <span className="sr-only">Navigation</span>
        <svg width="24" height="24" fill="none" aria-hidden="true">
          <path
            d="M12 6v.01M12 12v.01M12 18v.01M12 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <Dialog
        as="div"
        className={clsx('fixed z-50 inset-0', display)}
        open={isOpen}
        onClose={setIsOpen}>
        <Dialog.Overlay className="fixed inset-0 bg-black/20 backdrop-blur-sm dark:bg-slate-900/80" />
        <div className="fixed top-4 right-4 w-full max-w-xs bg-white rounded-lg shadow-lg p-6 text-base font-semibold text-slate-900 dark:bg-slate-800 dark:text-slate-400 dark:highlight-white/5">
          <button
            type="button"
            className="absolute top-5 right-5 w-8 h-8 flex items-center justify-center text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300"
            onClick={() => setIsOpen(false)}>
            <span className="sr-only">Close navigation</span>
            <svg viewBox="0 0 10 10" className="w-2.5 h-2.5 overflow-visible" aria-hidden="true">
              <path
                d="M0 0L10 10M10 0L0 10"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
          <ul className="space-y-6">
            <NavItems />
            <li>
              <a
                href="https://twitter.com/trainasme"
                className="hover:text-sky-500 dark:hover:text-sky-400">
                Twitter
              </a>
            </li>
            <li>
              <a
                href="https://instagram.com/trainasme"
                className="hover:text-sky-500 dark:hover:text-sky-400">
                Instagram
              </a>
            </li>
            <li>
              <Link href="/legal/privacy-policy">
                <a className="hover:text-sky-500 dark:hover:text-sky-400">Privacy Policy</a>
              </Link>
            </li>
            <li>
              <Link href="/legal/terms-and-conditions">
                <a className="hover:text-sky-500 dark:hover:text-sky-400">Terms and Conditions</a>
              </Link>
            </li>
          </ul>
          {/* <div className="mt-6 pt-6 border-t border-slate-200 dark:border-slate-200/10">
            <ThemeSelect />
          </div> */}
        </div>
      </Dialog>
    </div>
  );
}

export function NavItems() {
  return (
    <>
      {/* <li>
        <Link href="/docs/installation">
          <a className="hover:text-sky-500 dark:hover:text-sky-400">Docs</a>
        </Link>
      </li> */}
      <li>
        <a
          href="https://apps.apple.com/app/train-as-me/id1591739659"
          className="hover:text-sky-500 dark:hover:text-sky-400">
          App Store
        </a>
      </li>
      <li>
        <a
          href="https://play.google.com/store/apps/details?id=com.trainasme"
          className="hover:text-sky-500 dark:hover:text-sky-400">
          Play Store
        </a>
      </li>
    </>
  );
}
