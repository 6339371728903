import React from 'react';

interface PropsT {
  className?: string;
}

export const IconTwitter = (props: PropsT) => {
  const {className = 'fill-inherit'} = props;

  return (
    <svg x="0px" y="0px" viewBox="0 0 400 400">
      <path
        className={className}
        d="M400,200c0,110.5-89.5,200-200,200S0,310.5,0,200S89.5,0,200,0S400,89.5,400,200z M163.4,305.5
	c88.7,0,137.2-73.5,137.2-137.2c0-2.1,0-4.2-0.1-6.2c9.4-6.8,17.6-15.3,24.1-25c-8.6,3.8-17.9,6.4-27.7,7.6
	c10-6,17.6-15.4,21.2-26.7c-9.3,5.5-19.6,9.5-30.6,11.7c-8.8-9.4-21.3-15.2-35.2-15.2c-26.6,0-48.2,21.6-48.2,48.2
	c0,3.8,0.4,7.5,1.3,11c-40.1-2-75.6-21.2-99.4-50.4c-4.1,7.1-6.5,15.4-6.5,24.2c0,16.7,8.5,31.5,21.5,40.1c-7.9-0.2-15.3-2.4-21.8-6
	c0,0.2,0,0.4,0,0.6c0,23.4,16.6,42.8,38.7,47.3c-4,1.1-8.3,1.7-12.7,1.7c-3.1,0-6.1-0.3-9.1-0.9c6.1,19.2,23.9,33.1,45,33.5
	c-16.5,12.9-37.3,20.6-59.9,20.6c-3.9,0-7.7-0.2-11.5-0.7C110.8,297.5,136.2,305.5,163.4,305.5"
      />
    </svg>
  );
};

export const IconInstagram = (props: PropsT) => {
  const {className = 'fill-inherit'} = props;

  return (
    <svg viewBox="0 0 91 90" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          className={className}
          d="M45.2679 89.9699C40.5651 89.9699 35.8605 90.0518 31.1602 89.9469C26.5906 89.8447 21.9996 89.7527 17.5829 88.3168C8.71701 85.4342 3.30787 79.3575 1.39083 70.3117C0.724605 67.1679 0.477699 63.8913 0.431783 60.6695C0.289568 50.6742 0.259792 40.6776 0.342452 30.6797C0.375326 26.3517 0.535764 22.0023 1.82731 17.798C4.73555 8.33251 11.1827 2.8188 20.7941 0.928264C25.7195 -0.040225 30.7352 0.0387263 35.7281 0.0221144C43.5859 -0.00414301 51.4451 -0.024865 59.3019 0.0849876C63.7221 0.146612 68.1635 0.254323 72.4562 1.56344C81.9275 4.45194 87.4605 10.8891 89.3654 20.4954C90.3414 25.4175 90.2617 30.4332 90.2781 35.4247C90.3037 43.3106 90.3298 51.197 90.214 59.0826C90.1459 63.7089 90.0134 68.3513 88.5401 72.826C85.6439 81.6224 79.5833 86.9646 70.6204 88.8812C67.4213 89.5654 64.0828 89.7518 60.7989 89.8497C55.6261 90.0037 50.4458 89.8906 45.2688 89.8906C45.2679 89.9164 45.2679 89.9435 45.2679 89.9699ZM8.52263 44.9215H8.45831C8.45831 48.7308 8.36523 52.5424 8.4844 56.3477C8.60321 60.1443 8.65966 63.9763 9.23281 67.7181C10.2755 74.5265 14.2091 78.9856 20.9853 80.7638C24.6198 81.7176 28.3515 81.7362 32.0564 81.7807C40.2105 81.8783 48.3655 81.8816 56.5214 81.7907C60.3472 81.7428 64.2111 81.624 67.9828 81.048C74.7929 80.0082 79.2521 76.0728 81.0315 69.2991C81.9858 65.6654 82.0042 61.9345 82.0487 58.2302C82.1463 50.0782 82.1497 41.925 82.0587 33.7708C82.0108 29.946 81.8918 26.0829 81.3158 22.3118C80.2763 15.5029 76.3408 11.044 69.5644 9.26581C65.9298 8.31179 62.1982 8.29321 58.4933 8.24891C50.3392 8.15115 42.1841 8.14781 34.0281 8.23891C30.2024 8.28678 26.3385 8.40574 22.5666 8.98144C15.7563 10.0208 11.3003 13.9568 9.51599 20.73C8.67432 23.925 8.60285 27.2007 8.54479 30.4625C8.45885 35.2801 8.52263 40.101 8.52263 44.9215Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          className={className}
          d="M45.2632 68.1809C32.5232 68.1776 22.1014 57.757 22.1035 45.023C22.1058 32.2355 32.5348 21.8304 45.3329 21.8465C58.0634 21.8625 68.4555 32.2939 68.4477 45.0495C68.4404 57.7788 58.0119 68.1833 45.2632 68.1809ZM45.2337 59.9685C53.4784 59.9709 60.2271 53.2922 60.2343 45.1227C60.2416 36.8089 53.5829 30.0649 45.3627 30.0586C37.0592 30.0531 30.3221 36.7212 30.3178 44.9487C30.3137 53.2405 36.9926 59.966 45.2337 59.9685Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          className={className}
          d="M74.7344 21.0009C74.7337 22.0761 74.4141 23.127 73.8159 24.0205C73.2178 24.9141 72.3681 25.6103 71.3742 26.021C70.3803 26.4317 69.287 26.5385 68.2324 26.3279C67.1778 26.1173 66.2093 25.5987 65.4495 24.8378C64.6897 24.0769 64.1727 23.1077 63.9639 22.053C63.755 20.9983 63.8637 19.9053 64.2762 18.9124C64.6887 17.9194 65.3865 17.0711 66.2813 16.4746C67.176 15.8782 68.2277 15.5604 69.3031 15.5615C70.0175 15.5603 70.7251 15.7001 71.3852 15.9732C72.0453 16.2462 72.645 16.6469 73.1497 17.1524C73.6544 17.6579 74.0543 18.2581 74.3262 18.9186C74.5982 19.579 74.7369 20.2867 74.7344 21.0009Z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="90" height="90" className={className} transform="translate(0.300293)" />
        </clipPath>
      </defs>
    </svg>
  );
};
